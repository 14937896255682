.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  padding-left: 24px;
  padding-right: 32px;
  height: 64px;
  z-index: 10;
  line-height: 64px;
}

.padding24{
  padding: 0 24px;
}

.padding2432{
  padding: 0 32px 0 24px;
}
.background{
  background: white;
}

.headerH5 {
  display: none;
}

.hoverColor:focus,
.hoverColor:hover {
  color: #6F40EE;
}

.arrowRotate {
  transform: rotate(180deg);
}

.noPaddingTop {
  padding-top: 0;
}

.childrenMenuAcount {
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  font-family: 'DINPro' !important;
  font-weight: 500;
  color: #000000;
  padding-left: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 318px;
}
.childrenMenuKYCResult {
  position: relative;
  height: 40px;
}
.kycTag {
  position: absolute;
  right: 0;
  top: 0;
  height: 28px;
  line-height: 28px;
  border-radius: 12px 0 0 12px;
  margin: 6px 0;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
}
.noKYC {
  background-color: rgba(105, 111, 127, 0.1);
  color: #696F7F;
}
.kycIcon {
  font-size: 16px;
  margin-right: 8px;
}
.avatarContainer {
  position: relative;
  width: 32px;
  height: 32px;
}
.avatarCover {
  z-index: 9;
  position: absolute;
  top: 0px;
  left: 0;
	width: 32px;
	height: 32px;
  cursor: pointer;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.4);
	opacity: 0;
  border-radius: 16px;
}
.avatarCover:hover {
  transition: all .2s;
	width: 32px;
	height: 32px;
  border-radius: 16px;
	opacity: 1;
}

.content > .text {
  color: rgba(0, 0, 0 ,0.8);
  font-weight: 500;
}
.eye {
  margin-left: 12px;
  width: 20px;
  height: 20px;
  background-size: 20px;
}

.headerLeft .icon {
  margin: 0;
  margin-right: 2px;
}

.headerLeft>div{
  display: inline-block;
}


.focus :global .anticon{
  color: #6F40EE !important;
}
.focus span{
  color: #6F40EE !important;
}

.postActive{
  top: 0;
  border: none;
  box-shadow: none;
}
.postActive span{
  margin-left: 0;
  margin-right: 4px;
}


.postactiveContain :global .ant-btn{
  padding: 0;
  margin-left: 30px;
}

.languagePopverIcon{
  display: none;
}
.guideistChildrenMenClassname{
  padding: 0;
}

.headerLeft :global .ant-popover-inner-content{
  max-width: 900px;
}

.fireTitle{
  margin-right: 2px;
}
.headerRight {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.headerRight .menuSearch{
    border: none !important;
    /* top: 3px; */
}
.menuSearch{
  box-shadow: none;
  padding-right: 0;
  top: 1px;
}

@media (max-width: 960px) {
  .header {
    display: none;
  }
  .headerH5 {
    display: flex;
    height: 44px;
    padding-left: 15px;
    padding-right: 10px;
    background: white;
    z-index: 10;

  }
  .menuBtn {
    width: 24px;
    height: 24px;
    padding: 0;
    border: none;
    box-shadow: none;
  }
  .menuAvatar{
    border: none;
    /* margin-left: 17px; */
  }
  .menuAvatar{
    box-shadow: none;
  }

  .selectIcon{
    display: none;
  }

  .listTitle{
    height: 50px;
    font-family: DINPro-Medium;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    line-height: 50px;
    padding-left: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .listtag{
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .listtag .tag{
    height: 24px;
    font-weight: 400;
    font-size: 12px;
    border-radius: 24px 0 0 24px;
    padding-left: 6px;
    padding-right: 6px;
    display: flex;
    align-items: center;
  }
  .listtag .tag .icon{
    font-size: 12px;
  }
  .btnListpadding{
    padding-top: 0;
  }

  .line{
    border-top: 1px solid #F6F8FB;
  }
  .line:active,.line:hover{
    border-color:  #F6F8FB;
    border-radius: none;
  }
  .downloadBtn {
    display: flex;
  }
  .noRadius,
  .noRadius:focus {
    border-radius: 0 !important;
    border-color: #F6F8FB;
  }
}
