.img {
  width: 364px;
  height: 121px;
  margin-left: 41px;
}
.btn {
  width: 200px !important;
  height: 40px !important;
  border-radius: 8px !important;

}
.body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 64px);
}

.p {
  font-size: 28px;
  color: #434B5F;
  margin-top: 37px;
  margin-bottom: 47px;
}

.btnText {
  font-size: 16px;
}

@media screen and (max-width: 960px){
  .body {
    background-color: #fff !important;
  }
  .img {
    width: calc(511px / 2);
    height: calc(171px / 2);
    margin-left: 1em;
  }
  .p {
    font-size: 24px;
    margin-bottom: 60px;
    margin-top: 24px;
  }
}